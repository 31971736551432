import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppRoutingModule } from '../app-routing.module';
import { SharedModule } from '../shared/shared.module';
import { PaginationComponent } from './layout/pagination/pagination.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { BackButtonComponent } from './back-button/back-button.component';
import { ModalFileUploadComponent } from './modals/modal-file-upload/modal-file-upload.component';
import { ModalTransactionConfirmComponent } from './modals/modal-transaction-confirm/modal-transaction-confirm.component';
import { EmptyStateComponent } from './empty-state/empty-state.component';
import { NavbarMobileComponent } from './navbar-mobile/navbar-mobile.component';
import { ScrollTopComponent } from './scroll-top/scroll-top.component';


@NgModule({
  declarations: [
     PaginationComponent,
     BackButtonComponent,
     NotFoundComponent,
     ModalFileUploadComponent,
     ModalTransactionConfirmComponent,
     EmptyStateComponent,
     NavbarMobileComponent,
     ScrollTopComponent,
  ],
  imports: [
    CommonModule,
    AppRoutingModule,
    SharedModule,
  ],
  exports: [
    PaginationComponent,
    BackButtonComponent,
    ModalFileUploadComponent,
    ModalTransactionConfirmComponent,
    EmptyStateComponent,
    NavbarMobileComponent,
    ScrollTopComponent,
  ],
})
export class ComponentsModule { }

export class LoginModel {
    usuario: string;
    contrasena: string;
    id_pais? = '1';

    constructor() {
        this.usuario = 'WILSONM';
        this.contrasena = 'WILSONMa4?';
    }
}

import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { SelectsService } from 'src/app/services/selects.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { SubjectService } from 'src/app/services/subject.service';

// import * as _moment from 'moment';
// const moment = (_moment as any).default ? (_moment as any).default : _moment;

@Component({
  selector: 'app-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss']
})
export class FiltersComponent implements OnInit {

  public city = '';
  public state = '';
  public date: any;
  public minDate = new Date(2020, 11, 9);
  public maxDate = new Date();
  public dataOffices: any;
  public dataStates: any;
  public id: string;
  public subscriptionToggleStateFilter: Subscription;
  public showStateFilter = false;

  @Output() changeCity: EventEmitter<string> = new EventEmitter();
  @Output() changeState: EventEmitter<string> = new EventEmitter();
  @Input() color: string;


  constructor(public selects: SelectsService,
              public subject: SubjectService,
              public router: Router) {
    this.dataOffices = this.selects.dataOffices;
    this.dataStates = this.selects.dataStates;

    this.subscriptionToggleStateFilter  = this.subject.eventShowStateFilter$.subscribe((state: boolean) => {
      this.showStateFilter = state;
    });
  }

  ngOnInit() {}

  handleCity() {
    this.changeCity.emit(this.city);
  }

  handleState() {
   this.subject.sendState(this.state);
   console.log(this.city);

   if (this.state === 'todo') {
     this.date = '';
   }

  }

  handleSelectDate() {
    this.subject.sendDate(this.date);
  }

}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ComponentsModule } from '../components/components.module';
import { AppRoutingModule } from '../app-routing.module';
import { SharedModule } from '../shared/shared.module';
import { ModalLogoutComponent } from './shared/modals/modal-logout/modal-logout.component';
import { AdministrationComponent } from './administration/administration.component';
import { HomeComponent } from './public/home/home.component';
import { JobItemComponent } from './administration/job-item/job-item.component';
import { JobEditComponent } from './administration/job-edit/job-edit.component';
import { JobsListComponent } from './public/jobs-list/jobs-list.component';
import { JobDetailComponent } from './public/job-detail/job-detail.component';
import { HeaderComponent } from './administration/header/header.component';
import { CallToActionComponent } from './administration/call-to-action/call-to-action.component';
import { CandidatesComponent } from './administration/candidates/candidates.component';
import { JobFormComponent } from './administration/job-form/job-form.component';
import { FiltersComponent } from './public/filters/filters.component';
import { NewJobComponent } from './administration/new-job/new-job.component';
import { LegalComponent } from './public/legal/legal.component';

@NgModule({
  declarations: [
    AdministrationComponent,
    ModalLogoutComponent,
    HomeComponent,
    JobsListComponent,
    JobDetailComponent,
    JobItemComponent,
    JobEditComponent,
    HeaderComponent,
    CallToActionComponent,
    CandidatesComponent,
    JobFormComponent,
    FiltersComponent,
    NewJobComponent,
    LegalComponent,
  ],
  imports: [
    CommonModule,
    ComponentsModule,
    AppRoutingModule,
    SharedModule,
  ],
  exports: [
    AdministrationComponent,
    ModalLogoutComponent,
    FiltersComponent
  ]
})
export class PagesModule { }

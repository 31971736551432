import { Component, DoCheck } from '@angular/core';
import { ModalService } from 'src/app/services/modal.service';
import { SubjectService } from 'src/app/services/subject.service';
import { HelperService } from 'src/app/services/helper.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements DoCheck {
  public userName: string;
  public numberTransactions: number;
  public afifiliateName: string;
  public cityOffice: string;
  public documentType: string = 'N';
  public money = 123456789;
  public toggleMenu = false;


  public DataSelectFake = [
    { value: 1, description: 'Opción 1' },
    { value: 2, description: 'Opción 2' },
    { value: 3, description: 'Opción 3' },
  ];

  constructor(public modal: ModalService,
              private helper: HelperService,
              private subject: SubjectService) { }

  ngDoCheck() {
    const { NOMBRE, SUCURSAL } = JSON.parse(localStorage.getItem('identityWWU'));
    this.userName = NOMBRE;
    switch (SUCURSAL) {
      case 'Col - Zona - Centro - Bogota':
        this.cityOffice = 'Bogotá D.C.';
        break;

      default:
        this.cityOffice = 'Covinoc S.A.';
        break;
    }
  }

  refresh() {
    this.helper.refresh('/administracion');
  }

  handleToggleMenu() {
   this.toggleMenu = !this.toggleMenu;
   this.subject.sendMenuToggle(this.toggleMenu)
   return this.toggleMenu;
  }

}

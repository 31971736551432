import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PagesComponent } from './pages.component';
import { AuthGuard } from '../guards/auth.guard';

import { AdministrationComponent } from './administration/administration.component';
import { JobDetailComponent } from './public/job-detail/job-detail.component';
import { HomeComponent } from './public/home/home.component';
import { JobItemComponent } from './administration/job-item/job-item.component';
import { JobEditComponent } from './administration/job-edit/job-edit.component';
import { NewJobComponent } from './administration/new-job/new-job.component';
import { LegalComponent } from './public/legal/legal.component';


const routes: Routes = [
  { path: 'administracion',
    component: PagesComponent,
    children: [
      { path: '', component: AdministrationComponent, canActivate: [AuthGuard] },
      { path: 'editar-empleo/:id', component: JobEditComponent, canActivate: [AuthGuard] },
      { path: 'crear-oferta-de-empleo', component: NewJobComponent, canActivate: [AuthGuard] },
      { path: '**', pathMatch: 'full', redirectTo:  '/not-found' },
    ],
  },
  { path: 'app',
  component: PagesComponent,
  children: [
      { path: 'empleos', component: HomeComponent },
      { path: 'detalle-empleo/:id', component: JobDetailComponent },
      { path: 'terminos-y-condiciones', component: LegalComponent },
      { path: '**', pathMatch: 'full', redirectTo:  '/not-found' },
  ],
}
];

@NgModule({
  declarations: [],
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PagesRoutingModule { }

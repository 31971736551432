import { Component, Output, EventEmitter, Input } from '@angular/core';
import { HelperService } from 'src/app/services/helper.service';
import { Subscription } from 'rxjs';
import { SubjectService } from 'src/app/services/subject.service';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent {
  public pages = [];
  public selectedPage = 0;

  @Output() idSelectedPage: EventEmitter<object> = new EventEmitter();
  @Input() cantPages: number;
  @Input() limit: number;
  @Input() currentPage: number;

  public subscriptionChangeCity: Subscription;

  constructor(public helper: HelperService,
              private subject: SubjectService) {
              this.subscriptionChangeCity = this.subject.eventSelectedCity$.subscribe((city: any) => {
                this.selectedPage = 0;
              });
  }


  selectPage(idx: number) {
    if (idx + 1 === this.selectedPage + 1) {
      return;
    }
    this.selectedPage = idx;
    this.idSelectedPage.emit({
    current_page: this.selectedPage + 1,
    limite: this.limit,
   });
  }

  nextPage() {
    if (this.selectedPage >= this.pages.length - 1) {
      return;
    }
    this.selectedPage ++;
    this.idSelectedPage.emit({
      current_page: this.selectedPage + 1,
      limite: this.limit,
    });
  }

  previousPage() {
    if (!this.selectedPage) {
      return;
    }
    this.selectedPage --;
    this.idSelectedPage.emit({
      current_page: this.selectedPage + 1,
      limite: this.limit,
    });
  }

  limitChange() {
    this.idSelectedPage.emit({
      current_page: this.selectedPage + 1,
      limite: this.limit,
    });
  }

  createPages(cantPages: number) {
    const arrPages = Array.from(new Array(cantPages), (x, i) => i + 1);
    this.pages = arrPages;
    return arrPages;
  }

}

import { Injectable  } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponseBase } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { IdentityModel } from '../models/identity.model';
import { map } from 'rxjs/operators';
import * as _moment from 'moment';
import { environment } from 'src/environments/environment';
import { JobModel } from '../models/job.model';

@Injectable({
  providedIn: 'root'
})
export class BackEndService {
  protected ngUnsubscribe: Subject<void> = new Subject<void>();

  // properties
  private urlApi: string;

  public identity = new IdentityModel();
  public token: any;
  public Headers: any;

  constructor( public http: HttpClient ) {
    this.urlApi = environment.urlApi;
    this.Headers = new HttpHeaders();
    this.Headers = this.Headers.set('Content-Type', 'application/x-www-form-urlencoded');
  }

    getquery(query?: string, params?: any) {
      let headers = new HttpHeaders();
      headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');

      const options = { params, headers };
      return this.http.get(`${this.urlApi}/${query}`, options);
    }

    getJob(id: string) {
      return this.getquery(`job?rol=ADMIN&id=${id}`);
    }

    getJobs(current_page?: number, items?: number, status?: boolean, city?: string) {
      const queryParams = {
        rol: 'ADMIN',
        current_page,
        items,
        status,
        city
      };

      for (const key in queryParams) {
        if (!queryParams[key]) {
        delete queryParams[key];
        }
      }

      return this.getquery(`jobs`, queryParams);
    }

    getJobsList(current_page?: number, items?: number, status?: boolean, city?: string) {
      const queryParams = {
        current_page,
        items,
        status,
        city
      };

      for (const key in queryParams) {
        if (!queryParams[key]) {
        delete queryParams[key];
        }
      }

      return this.getquery(`jobs_list`, queryParams);
    }

    postNewJob(newJob: JobModel) {
      let headers = new HttpHeaders();
      headers = this.Headers.set('Content-Type', 'application/json');
      const { NOMBRE, SUCURSAL } = JSON.parse(localStorage.getItem('identityWWU')) ||
                                   { NOMBRE: 'Admin', SUCURSAL: 'Col - Zona - Centro - Bogota' };
      newJob.author = NOMBRE;
      newJob.author_city = SUCURSAL;
      const body = newJob;
      const params = {};
      const options = { params, headers };
      return this.http.post(`${this.urlApi}/new_job`, body, options);
    }

    putUpdateJob(id: string, job: JobModel) {
      let headers = new HttpHeaders();
      headers = this.Headers.set('Content-Type', 'application/json');
      const body = job;
      const params = {};
      const options = { params, headers };
      return this.http.put(`${this.urlApi}/update_job/${id}?rol=ADMIN`, body, options);
    }

    putUpdateJobState(job: JobModel) {
      let headers = new HttpHeaders();
      headers = this.Headers.set('Content-Type', 'application/json');
      const { status } = job;
      const { id } = job;
      const body = { status };
      const options = { headers };
      return this.http.put(`${this.urlApi}/update_job_state/${id}?rol=ADMIN`, body, options);
    }

    postNewCandidate(newCandidate: any) {
      let headers = new HttpHeaders();
      headers.append('Content-Type', 'multipart/form-data');
      headers.append('Accept', 'application/x-www-form-urlencoded');

      let today = new Date();
      today.setHours(0,0,0,0);

      newCandidate.date = today.getTime();

      const formData: FormData = new FormData();
      const file = newCandidate.cv;
      // formData.append('cv', file, file.name);
      for (const key in newCandidate) {
        if (newCandidate.hasOwnProperty(key)) {
            formData.append(key, newCandidate[key]);
        }
      }
      const params = {};
      const options = { params, headers };
      return this.http.post(`${this.urlApi}/new_application`, formData, options);
    }

    getCandidates(current_page?: number, items?: number, id?: string, filter?: any, date?: string) {
      const queryFilter: any = {};

      if (filter && typeof filter !== 'object') {
        queryFilter[filter] = true;
      }

      const queryParams = {
        rol: 'ADMIN',
        current_page,
        date,
        items,
        ...queryFilter,
      };

      return this.getquery(`candidates/${id}`, queryParams);
    }


    getNewCandidates(date?: Date) {
      const queryParams = {
        rol: 'ADMIN',
        date,
      };

      console.log('today: ', date);
      return this.getquery(`count_new_candidates`, queryParams);
    }

    getCv(cvPath: string) {
      let headers = new HttpHeaders();
      headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');

    //   const options = { headers };
    //   return this.http.get(`${this.urlApi}/cv?cv=${cvPath}`, options);
      const httpOption: Object = {
          observe: 'response',
        //   params,
          headers,
          responseType: 'blob'
      };

      return this.http.get(`${this.urlApi}/cv?cv=${cvPath}`, httpOption).pipe(map((res: HttpResponseBase) => {
        return {
          filename: cvPath,
          data: new Blob(
            [res['body']],
            { type: 'application/pdf' }
          ),
        };
      }));
    }

    putUpdateCandidateState(candidate: any) {
        let headers = new HttpHeaders();
        headers = this.Headers.set('Content-Type', 'application/json');
        const { _id } = candidate;
        const body = { ...candidate };
        const options = { headers };
        return this.http.put(`${this.urlApi}/candidate_state/${_id}?rol=ADMIN`, body, options);
      }

  }

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalService } from '../services/modal.service';
import { HelperService } from '../services/helper.service';
import { SubjectService } from '../services/subject.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-pages',
  templateUrl: './pages.component.html',
  styleUrls: ['./pages.component.scss']
})
export class PagesComponent implements OnInit {
  navigation = [
    { alt: 'Crear/editar ofertas', icon: 'fas fa-list', path: '/administracion', visible: this.helper.validateStorage('identityWWU') },
    // { alt: 'Agregar ofertas', icon: 'fas fa-plus', path: '/administracion/editar-empleo' },
    { alt: 'Página principal', icon: 'fas fa-home', path: '/app/empleos', visible: true },
  ];

  public city: string;
  public menuToggle = false;
  public subscriptionMenuToggle: Subscription;

  constructor(private subject: SubjectService,
              private modal: ModalService,
              public router: Router,
              private helper: HelperService) {
                this.subscriptionMenuToggle = this.subject.eventStateMenu$.subscribe((state: boolean) => {
                   this.menuToggle = state; 
                });
              }

  ngOnInit() {
  }

  showModalLogOut() {
      this.modal.show('logOut');
  }

  handleChangeCity(city: string) {
       this.city = city;
       this.subject.sendCity(this.city);
  }

  handleLinkedin() {
    window.open(
       'https://co.linkedin.com/company/covinoc',
       '_blank'
    );
  }

}

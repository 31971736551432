import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
declare let gtag: any;

@Component({
  selector: 'app-job-item',
  templateUrl: './job-item.component.html',
  styleUrls: ['./job-item.component.scss']
})
export class JobItemComponent implements OnInit {

  @Input() jobDetails: any;
  @Input() admin = false;
  @Output() status: EventEmitter<object> = new EventEmitter();

  public checkState = false;

  constructor(private router: Router) { }

  ngOnInit() {
  }

  jobDescription(id: string) {
    console.log(id);
    this.router.navigate(['/admin/detalle-empleo']);
  }

  handleCheckbox(status: boolean, id: string) {
    this.status.emit({
       status, id
    });
  }

  tag(jobName: string, jobCity: string){
    (window as any).gtag('event', 'ver_oferta',
    { event_category: `${jobName}-${jobCity}` , event_label: ''});
  }

}

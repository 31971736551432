import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalService } from 'src/app/services/modal.service';
import { HelperService } from 'src/app/services/helper.service';
import { SelectsService } from 'src/app/services/selects.service';
import { SubjectService } from 'src/app/services/subject.service';

@Component({
  selector: 'app-job-edit',
  templateUrl: './job-edit.component.html',
  styleUrls: ['./job-edit.component.scss']
})
export class JobEditComponent implements OnInit {


  public id: string;
  public totalCandidates = 0;

  public navItemTabs = [
      { href: '#candidates', description: 'Candidatos', icon: 'fas fa-tasks' },
      { href: '#edit', description: 'Editar vacante', icon: 'fas fa-edit' },
  ];

  constructor(public router: Router,
              public activedRoute: ActivatedRoute,
              public helper: HelperService,
              public selects: SelectsService) {
                this.activedRoute.params.subscribe((params: any) => {
                  this.id = params.id;
                });
  }

  ngOnInit() {
    if(this.id) {
      this.helper.toggleStateFilter(true);
    }
  }

  handleShowFilter(link: string) {
    let state = false;

    if (link === '#candidates') {
      state = true;
    }
    this.helper.toggleStateFilter(state);
  }


  total(candidates: number) {
    this.totalCandidates = candidates;
  }

}

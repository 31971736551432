import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-password',
  templateUrl: './password.component.html',
  styleUrls: ['./password.component.scss']
})
export class PasswordComponent implements OnInit {

  // Properties
  FormPass: FormGroup;
  objPass = {
   usuario: '',
  };

  // Toggles
  public toggleFormPass = false;

  constructor( private auth: AuthService,
               private router: Router) {
    this.setForm();
}

ngOnInit() {
}

setForm() {
  this.FormPass = new FormGroup({
    usuario: new FormControl('', [Validators.required, Validators.minLength(4)]),
  });
  this.FormPass.controls['usuario'].setValidators([
    Validators.required,
  ]);

  this.FormPass.setValue( this.objPass );
}

recoverPassword() {
  this.toggleFormPass = true;
}


pass() {
  this.router.navigateByUrl('pages/transacciones');
}

// Swal.fire({
//   type: 'error',
//   title: 'Error al cambiar contraseña',
//   text: 'Error de contraseña'
//   });


}

import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { ModalService } from 'src/app/services/modal.service';

import * as FileSaver from 'file-saver';
import * as XLS from 'xlsx';
import * as XLSX from 'xlsx';
import { ToastrService } from 'ngx-toastr';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xls';

@Component({
  selector: 'app-modal-file-upload',
  templateUrl: './modal-file-upload.component.html',
  styleUrls: ['./modal-file-upload.component.scss']
})
export class ModalFileUploadComponent implements OnInit {

    public fileSelected: File;
    public files: File[] = [];
    public email: string;
    public validformat = 'application/pdf';
    public acceptTerm = false;
    @Output() chargeResult: EventEmitter<object> = new EventEmitter();
    @Input() jobDetail: any;

    constructor(public modal: ModalService,
                public toastr: ToastrService,
                public router: Router) { }

    ngOnInit() { }

  // DropZone
  onSelect(event) {
    if (event.rejectedFiles && event.rejectedFiles[0] && event.rejectedFiles[0].type !== this.validformat) {
      this.toastr.error('El único formato válido es .pdf');
    }

    if (event.addedFiles && event.addedFiles[0] && event.addedFiles[0].size > 3000000) {
      this.toastr.error('El peso máximo permitido es 2MB');
    }

    this.fileSelected = event.addedFiles[0];
    this.files.push(...event.addedFiles);
  }

  onRemove(event) {
    this.files.splice(this.files.indexOf(event), 1);
    this.fileSelected = null;
  }

  goToLegal() {
   window.open(
     '#/app/terminos-y-condiciones', '_blank'
   );
  }

  // DropZone
  uploadFile(formApply: NgForm) {
    this.modal.close();
    const { _id, job_name, city } = this.jobDetail;

    this.chargeResult.emit(
      {
        email: this.email,
        job_name,
        job_id: _id,
        city,
        cv: this.fileSelected
      }
    );

    formApply.resetForm();
    this.fileSelected = null;
    this.files = [];
  }


}

import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-empty-state',
  templateUrl: './empty-state.component.html',
  styleUrls: ['./empty-state.component.scss']
})
export class EmptyStateComponent {

  @Input() type: string;

  constructor() { }

  handleCovinocHome() {
    window.location.href = 'https://covinoc.com';
  }

}

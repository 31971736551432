import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';
import Swal from 'sweetalert2';
import { AuthService } from 'src/app/services/auth.service';
import { LoginModel } from 'src/app/models/login.model';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AnalyticsModel } from 'src/app/models/analytics.model';
declare let gtag: any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']

})
export class LoginComponent implements OnInit {

  // Props
  public usuario: LoginModel = new LoginModel();
  public FormLogin: FormGroup;
  public title: string;
  public hidePassword = true;

  ObjLogin = {
    usuario: '',
    contrasena: ''
  };

  public authorizedUsers = ['alba.barrios', 'lina.gonzalez', 'maria.navarro', 'edgar.manchego'];

  public year = new Date();

  constructor( private auth: AuthService,
               private readonly route: ActivatedRoute,
               private router: Router,
               private toastr: ToastrService,
              ) {

                this.FormLogin = new FormGroup({
                  usuario: new FormControl('', [Validators.required, Validators.minLength(4)]),
                  contrasena: new FormControl('', [Validators.required, Validators.minLength(4)]),
                });

                this.FormLogin.setValue( this.ObjLogin );
    }

    ngOnInit() {
    }

    pass() {
      this.router.navigateByUrl('/contrasena');
    }

    login( form?: NgForm, dataLogin?: object ) {
      if (dataLogin) {
        this.FormLogin.setValue(dataLogin);
      }

      const user = this.FormLogin.controls['usuario'].value;

      if (!this.authorizedUsers.includes(user)) {
        Swal.fire({
          type: 'error',
          title: 'Usuario no autorizado.',
          text: 'Por favor comuníquese con el área encargada.'
        });
        return;
      }

      Swal.fire({
          allowOutsideClick: false,
          type: 'info',
          text: 'Espere por favor...'
      });

      Swal.showLoading();

      this.auth.login( this.FormLogin.value )
        .subscribe( (resp: any) => {
          Swal.close();
          localStorage.setItem('identityWWU', JSON.stringify(resp));
          this.router.navigateByUrl('/administracion');
          this.toastNotification(resp.NOMBRE, 'Bienvenida@');
          (window as any).gtag('event', 'cerrar_sesion',
          { event_category: `${resp.TIPO_DOCUMENTO} ${resp.DOCUMENTO}` , event_label: ''});
        }, (err) => {
          (window as any).gtag('event', 'no entró', { event_category: 'prelogin' , event_label: ''});
          Swal.fire({
            type: 'error',
            title: 'Alerta:',
            text: 'Usuario o password incorrectos.'
          });
        });
    }

    togglePassword() {
       this.hidePassword = !this.hidePassword;
    }

    toastNotification(titulo: string, descripcion: string) {
       this.toastr.success(titulo, descripcion);
    }

  }

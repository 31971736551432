  export class JobModel {
    job_name?: string;
    salary?: number;
    city?: string = '';
    date?: any;
    job_description?: string;
    important_description?: string;
    total_vacancies?: string = '';
    years_experience?: string = '';
    academic_level?: string = '';
    status?: boolean;
    id?: string = '';
    author?: string;
    author_city?: string;
  }

import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import Swal from 'sweetalert2';
import { ActivatedRoute, Router } from '@angular/router';
import { BackEndService } from 'src/app/services/back-end.service';
import { HelperService } from 'src/app/services/helper.service';
import { SelectsService } from 'src/app/services/selects.service';
import { JobModel } from 'src/app/models/job.model';
import * as _moment from 'moment';
const moment = (_moment as any).default ? (_moment as any).default : _moment;

@Component({
  selector: 'app-job-form',
  templateUrl: './job-form.component.html',
  styleUrls: ['./job-form.component.scss']
})
export class JobFormComponent implements OnInit {

  public id: string;
  public dataJob: any;
 
  public today = new Date();
  // public minDate = new Date(new moment(this.today).subtract(1, 'days'));
  public minDate = new Date('2021-01-02');

  public maxDate = new Date();

  public data = new JobModel();
  public dataOffices: any;
  public dataNumbers: any;
  public dataAcademicLevel: any;

  constructor(private activedRoute: ActivatedRoute,
              private backend: BackEndService,
              public router: Router,
              public helper: HelperService,
              public selects: SelectsService) {
    this.activedRoute.params.subscribe((params: any) => {
      this.id = params.id;
    });

  }

  ngOnInit() {
    if (this.id) {
      this.getJob();
    } else {
      this.data.date = new Date();
    }
   
    this.dataOffices = this.selects.dataOffices;
    this.dataNumbers = this.selects.dataNumbers;
    this.dataAcademicLevel = this.selects.dataAcademicLevel;
  }

  getJob() {
    this.backend.getJob(this.id)
    .subscribe((resp: any) => {
        this.dataJob = resp.job[0];
        this.data = this.dataJob;
    }, (err) => {
      console.log(err);
    });
  }

  save() {
    if (this.id) {
      this.updateJob();
    } else {
      this.newJob();
    }
  }

  newJob() {
    this.backend.postNewJob(this.data)
    .subscribe((resp: any) => {
      console.log(resp);
      Swal.fire({
         type: 'success',
         title: `Nueva vacante creada exitosamente.`,
        //  text: `${resp.ok}`
      }).then((result) => {
        this.router.navigateByUrl('/administracion');
      });
    }, (err) => {
      console.log(err);
      Swal.fire({
        type: 'error',
        title: `No se pudo crear`,
        text: `${err.message}`
      });
    });
  }

  updateJob() {
    this.backend.putUpdateJob(this.id, this.data)
    .subscribe((resp: any) => {
      console.log(resp);
      Swal.fire({
        type: 'success',
        title: `Vacante actualizada exitosamente.`,
        // text: `${resp.ok}`
      }).then((result) => {
        this.router.navigateByUrl('/administracion');
      });
    }, (err) => {
      console.log(err);
      Swal.fire({
        type: 'error',
        title: `No se pudo actualizar`,
        text: `${err.message}`
      });
    });
  }
}


import { NgModule, LOCALE_ID } from '@angular/core';
import { CommonModule, registerLocaleData } from '@angular/common';
import { DocumentTypePipe } from '../pipes/document-type.pipe';
import { BgUrlPipe } from '../pipes/bg-url.pipe';
import { FormsModule } from '@angular/forms';
import { NgxSpinnerModule } from 'ngx-spinner';
import { UiSwitchModule } from 'ngx-ui-switch';
import { AlertColorDirective } from '../directives/alert-color.directive';
import { OutsideDirective } from '../directives/outside.directive';
import { UppercaseDirective } from '../directives/uppercase.directive';
import { OnlyNumberDirective } from '../directives/only-number.directive';
import { ReplacePipe } from '../pipes/replace.pipe';
import { FilterPipe } from '../pipes/filter.pipe';
import { SumPipe } from '../pipes/sum.pipe';
import { TooltipDirective } from '../directives/tooltip.directive';
import { PdfViewerModule  } from 'ng2-pdf-viewer';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { OwlDateTimeModule, OwlNativeDateTimeModule, OWL_DATE_TIME_LOCALE, OwlDateTimeIntl } from 'ng-pick-datetime';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import localeEsCO from '@angular/common/locales/es-CO';

registerLocaleData(localeEsCO, 'es-Co');

export class DefaultIntl extends OwlDateTimeIntl  {
  rangeFromLabel = 'Fecha inicial';
  rangeToLabel = 'Fecha final';
  hour12AMLabel = 'AM';
  hour12PMLabel = 'PM';
  prevMultiYearLabel = '21 años atrás';
  nextMultiYearLabel = 'Próximos 21 años';
}

@NgModule({
  declarations: [
    DocumentTypePipe,
    BgUrlPipe,
    AlertColorDirective,
    OutsideDirective,
    UppercaseDirective,
    OnlyNumberDirective,
    ReplacePipe,
    FilterPipe,
    SumPipe,
    TooltipDirective,
  ],
  imports: [
    CommonModule,
    FormsModule,
    NgxSpinnerModule,
    PdfViewerModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    BrowserAnimationsModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    UiSwitchModule.forRoot({
      // size: 'small',
      color: 'rgb(61, 160, 48)',
      switchColor: '#FFFFFF',
      defaultBgColor: 'rgb(151, 151, 151)',
      defaultBoColor : '#dadada',
      checkedLabel: 'Si',
      uncheckedLabel: 'No'
    }),
    NgxDropzoneModule
  ],
  providers: [
    { provide: OWL_DATE_TIME_LOCALE, useValue: 'es' },
    { provide: OwlDateTimeIntl, useClass: DefaultIntl },
    { provide: LOCALE_ID, useValue: 'es-Co' }
  ],
  exports: [
    DocumentTypePipe,
    BgUrlPipe,
    FormsModule,
    NgxSpinnerModule,
    AlertColorDirective,
    OutsideDirective,
    UppercaseDirective,
    OnlyNumberDirective,
    ReplacePipe,
    UiSwitchModule,
    FilterPipe,
    SumPipe,
    TooltipDirective,
    NgxDropzoneModule,
    PdfViewerModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    BrowserAnimationsModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
  ]
})
export class SharedModule { }

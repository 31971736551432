import { Injectable } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { SubjectService } from './subject.service';

@Injectable({
  providedIn: 'root'
})
export class HelperService {

  constructor(private toastr: ToastrService,
              private subject: SubjectService,
              private router: Router) { }

  onlyNumber($event: any) {
    const arrayCodes = [8, 9, 37, 39];
    if (arrayCodes.includes($event.keyCode)) {
          return;
       } else if (arrayCodes.indexOf($event.keyCode) === -1 && $event.key.search(/\d\b/) === -1) {
          $event.preventDefault();
    }
  }

  currencyInputChanged(value: any) {
    if (!value) {
      return;
    }
    const num = value.replace(/[\$,\. ]/g, '');
    return Number(num);
  }

  markForm(form?: NgForm, toast?: boolean, time?: number, textToast?: string) {
    const timeOut = time || 12000;
    const markForm = form.form.controls;
    const message = textToast || 'Los campos marcados con rojo son obligatorios.';

    setTimeout(() => {
          Object.keys(markForm).forEach((control, index) => {
          form.form.controls[control].markAsTouched(); });
          if (toast) {
            this.toastr.warning(message, null, {
              timeOut,
              positionClass: 'toast-bottom-right'
           });
          }
    }, 500);
  }

  trackByFn(index: any, item: any) {
      return index;
  }

  findOffice(office: string) {

    switch (office) {
      case 'Col - Zona - Centro - Bogota':
      office = 'bogota';
      break;
      case 'Col - Zona - Costa - Barranquilla':
      office = 'barranquilla';
      break;
      case 'Col - Zona - Santander – Bucaramanga':
      office = 'bucaramanga';
      break;
      case 'Col - Zona - Suroccidente – Cali':
      office = 'cali';
      break;
      case 'Col - Zona - Antioquia – Medellin':
      office = 'medellin';
      break;
      case 'Col - Zona - Eje Cafetero – Pereira':
      office = 'pereira';
      break;

      default:
      office = '';
      break;
    }

    return office;
  }

  toggleStateFilter(state: boolean) {
   this.subject.toggleStateFilter(state);
  }

  validateStorage(item: string) {
    return localStorage.getItem(item);
  }

  refresh(actualComponent: string) {
    this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
      this.router.navigate([`${actualComponent}`]);
  });
  }

  logOut() {
    this.router.navigateByUrl('/admin/login');
    localStorage.clear();
  }

}

import { Component, OnInit, HostListener, DoCheck } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { AnalyticsModel } from './models/analytics.model';
import { BackEndService } from './services/back-end.service';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
declare let gtag: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit, DoCheck {
  public identity: any;
  public currentRoute: string;
  public environment: string;
  public analytics: AnalyticsModel;
  isShow: boolean;
  topPosToStartShowing = 100;
  @HostListener('window:scroll')
  checkScroll() {
  const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;

  if (scrollPosition >= this.topPosToStartShowing) {
    this.isShow = true;
  } else {
    this.isShow = false;
   }
  }

  constructor( public router: Router,
               private toastr: ToastrService,
               public backend: BackEndService) {
                      // Analytics
                      const navEndEvents$ = this.router.events
                      .pipe(
                        filter(event => event instanceof NavigationEnd)
                      );

                      navEndEvents$.subscribe((event: NavigationEnd) => {
                        this.currentRoute = event.url;
                        gtag('config', 'G-G7PR0QFQ9P', {
                          page_path: event.urlAfterRedirects
                        });
                      });
               }

    ngOnInit() {
      this.analytics = JSON.parse(localStorage.getItem('identityWWU')) || {};
      this.environment = environment.production ? 'Prod' : 'Dev';
      console.info(this.environment, new Date(2024, 8, 3), "Fix Ajuste fontface reemplazó la CDN");
      if(this.analytics && this.analytics.TIPO_DOCUMENTO) {
        let today = new Date();
        today.setHours(0,0,0,0)
        this.getNewCandidates(today);
      }
    }

    ngDoCheck() {
      this.identity = JSON.parse(localStorage.getItem('identityWWU')) || {};
      this.analytics = this.identity;
    }

    gotoTop() {
        window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth'
        });
    }

    noAuth() {
      const authRoutes = ['/login', '/forgot'];
      return authRoutes.includes(this.currentRoute);
    }

    getNewCandidates(date: Date) {
      this.backend.getNewCandidates(date)
          .subscribe((resp: any) => {
            const { total_registers } = resp;
            if(total_registers > 0) {
              this.toastr.success('', `${total_registers} Nuevos candidatos hoy.`, {
                timeOut: 6000,
              });
            } else {
              this.toastr.info('', 'No hay nuevos candidatos...', {
                timeOut: 6000,
              });
            }
          }, (err: any) => console.log);
    }

    logOut() {
      this.router.navigate(['/login']);
      (window as any).gtag('event', 'cerrar_sesion',
      { event_category: `${this.analytics.TIPO_DOCUMENTO} ${this.analytics.DOCUMENTO}` , event_label: ''});
      localStorage.clear();
    }

}


import { Component, OnInit } from '@angular/core';
import { BackEndService } from 'src/app/services/back-end.service';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
import { SubjectService } from 'src/app/services/subject.service';
import { Subscription } from 'rxjs';
import { HelperService } from 'src/app/services/helper.service';

@Component({
  selector: 'app-administration',
  templateUrl: './administration.component.html',
  styleUrls: ['./administration.component.scss']
})
export class AdministrationComponent implements OnInit {
  public dataJobsList: any;
  public totalPages: any;
  public subscriptionChangeCity: Subscription;
  public jobCity: string;

  constructor(private backend: BackEndService,
              public subject: SubjectService,
              public helper: HelperService,
              public spinner: NgxSpinnerService) {
                this.subscriptionChangeCity = this.subject.eventSelectedCity$.subscribe((city: any) => {
                   this.spinner.show();
                   this.jobCity = city;
                   this.jobList({current_page: 0});
                });
              }

  ngOnInit() {
    this.spinner.show();
    this.jobList({current_page: 0});
  }


  jobList({current_page}) {
    // this.spinner.show();
    this.backend.getJobs(current_page, 4, undefined, this.jobCity)
        .subscribe((resp: any) => {
          const { jobs, pages } = resp;
          this.dataJobsList = jobs;
          this.totalPages = pages;
          this.spinner.hide();
        }, (err) => {
          this.spinner.hide();
          this.dataJobsList = [];
        });
  }

  handleChangeStatus(data: any) {
    this.spinner.show();
    this.backend.putUpdateJobState(data)
        .subscribe((resp: any) => {

          const { job_name } = resp;
          const yesOrNot = data.status ? '' : 'no';
          const newStatus = data.status ? 'Activado' : 'Inactivado';

          this.spinner.hide();

          Swal.fire({
            type: 'success',
            title: `El cargo: ${job_name}`,
            text: `Ha sido: ${newStatus} y por lo tanto ${yesOrNot} será visible para los candidatos.`,
          });
        }, (err: any) => {
          console.log(err);
          this.spinner.hide();
          Swal.fire({
            type: 'error',
            title: `No está disponible.`,
            text: `Por favor intente más tarde.`,
          });
        });
  }

}

import { Component, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BackEndService } from 'src/app/services/back-end.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { HelperService } from 'src/app/services/helper.service';
import { Subscription } from 'rxjs';
import { SubjectService } from 'src/app/services/subject.service';
import Swal from 'sweetalert2';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-candidates',
  templateUrl: './candidates.component.html',
  styleUrls: ['./candidates.component.scss']
})
export class CandidatesComponent implements OnInit, OnDestroy {

  public check: false;
  public id: string;
  public candidates: any;
  public totalPages = 0;
  public currentPage = 0;
  public selectedFilters: any;
  public subcriptionStateFilter: Subscription;
  public subscriptionChangeDate: Subscription;

  public dateFilter: string;
  public stateFilter: string;

  @Output() totalRegisters: EventEmitter<number> = new EventEmitter<number>();

  constructor(public activedRoute: ActivatedRoute,
              private spinner: NgxSpinnerService,
              private toastr: ToastrService,
              private helper: HelperService,
              private subject: SubjectService,
              private backend: BackEndService) {
    this.activedRoute.params.subscribe((params: any) => {
      this.id = params.id;
    });

    this.subcriptionStateFilter = this.subject.eventState$.subscribe((filter: string) => {
      this.stateFilter = filter;
      this.candidatesList({current_page: 1 }, this.stateFilter, this.dateFilter);
    });

    this.subscriptionChangeDate = this.subject.eventDate$.subscribe((filter: string) => {
      this.dateFilter = filter;
      this.candidatesList({current_page: 1 }, this.stateFilter, this.dateFilter);
    }); 

   }

  ngOnInit() {
    this.spinner.show();
    this.candidatesList({current_page: 0});
  }

  ngOnDestroy() {
    this.helper.toggleStateFilter(false);
  }

  candidatesList({current_page}, filter?: any, dateFilter?: string) {
    this.currentPage = current_page;

    this.backend.getCandidates(this.currentPage, 10, this.id, filter, dateFilter)
        .subscribe((resp: any) => {
           const { candidates, pages, current_page } = resp;
           this.currentPage = current_page;
           this.candidates = candidates;
           this.totalPages = pages;
           this.totalRegisters.emit(this.candidates.length);
           this.spinner.hide();
          }, (err) => {
            this.spinner.hide();
            console.log(err);
          }
       );
  }


  updateState(candidate: any) {
    this.spinner.show();
    const { email } = candidate;
    
    this.backend.putUpdateCandidateState(candidate)
    .subscribe((resp: any) => {
       this.spinner.hide();
       this.toastr.success('¡Actualizado correctamente!', `${email}`, {
        timeOut: 6000,
       });
      }, (err) => {
        console.log(err);
        this.spinner.hide();
        Swal.fire({
          type: 'error',
          title: '¡No se pudo actualizar',
          text: 'Por favor intente más tarde.'
         });
      }
   );
  }

  viewCv(cv: string) {
    this.spinner.show();
    this.backend.getCv(cv)
    .subscribe((resp: any) => {
       const url = URL.createObjectURL(resp.data);
       setTimeout(() => {
         window.open(url);
         this.spinner.hide();
       }, 3000);
      }, (err) => {
        console.log(err);
        this.spinner.hide();
      }
   );
  }

  toggleCheckbox(value: boolean, type: string, id: string) {

      if(type === 'selected')  {
        this.candidates[id].discarted = false;
        } else {
        this.candidates[id].discarted = true;
      }

      if(type === 'discarted')  {
        this.candidates[id].selected = false;
        } else {
        this.candidates[id].selected = true;
      }
  }

}

import { Component, HostListener } from '@angular/core';

@Component({
  selector: 'app-scroll-top',
  templateUrl: './scroll-top.component.html',
  styleUrls: ['./scroll-top.component.scss']
})
export class ScrollTopComponent {

    public screenHeight: any;
    public isShowWidth: boolean;
    public isShowHeigth: boolean;
    public innerWidth: any;
    @HostListener('window:resize', ['$event'])
    onResize(event) {

      this.screenHeight = window.innerHeight;
      this.innerWidth = window.innerWidth;

      if (this.screenHeight >= 1040) {
         this.isShowHeigth = true;
         } else {
         this.isShowHeigth = false;
      }

      if (this.innerWidth >= 900) {
         this.isShowWidth = true;
         } else {
         this.isShowWidth = false;
     }
    }
   // Document size
    public isShow: boolean;
    public topPosToStartShowing = 100;

    @HostListener('window:scroll')
    checkScroll() {
    const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;

    if (scrollPosition >= this.topPosToStartShowing) {
      this.isShow = true;
    } else {
      this.isShow = false;
     }
    }


  constructor() { }

  gotoTop() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

}

import { Injectable, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class SelectsService implements OnInit {

  public dataOffices = [
    { description: 'Mostrar todo', value: 'todo'},
    { description: 'Bogotá', value: 'Bogotá'},
    { description: 'Barranquilla', value: 'Barranquilla'},
    { description: 'Bucaramanga', value: 'Bucaramanga'},
    { description: 'Cali', value: 'Cali'},
    { description: 'Medellín', value: 'Medellín'},
    { description: 'Pereira', value: 'Pereira'},
    { description: 'Virtual - Cualquier ciudad', value: 'Virtual - Cualquier ciudad'},
  ];

  public dataAcademicLevel = [
    { description: 'Bachiller', value: 'Bachiller'},
    { description: 'Técnico', value: 'Técnico'},
    { description: 'Tecnólogo', value: 'Tecnólogo'},
    { description: 'Profesional', value: 'Profesional'},
    { description: 'Especialización', value: 'Especialización'},
    { description: 'Maestría', value: 'Maestría'},
    { description: 'Doctorado', value: 'Doctorado'},
  ];

  public dataStates = [
    { description: 'Todo', value: 'todo'},
    { description: 'Visto', value: 'view' },
    { description: 'Seleccionado', value: 'selected' },
    { description: 'Descartado', value: 'discarted'}
  ];

  public dataNumbers = [ 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, ];

  constructor( private http: HttpClient ) {

  }

  ngOnInit() {
  }

}

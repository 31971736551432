import { Component, OnInit } from '@angular/core';
import { BackEndService } from 'src/app/services/back-end.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { SubjectService } from 'src/app/services/subject.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  public dataJobsList: any;
  public totalPages: number;
  public currentPage: number;
  public jobCity: string;
  public subscriptionChangeCity: Subscription;

  constructor(private backend: BackEndService,
              public subject: SubjectService,
              public spinner: NgxSpinnerService) {
                this.subscriptionChangeCity = this.subject.eventSelectedCity$.subscribe((city: any) => {
                  this.jobCity = city;
                  this.jobList({current_page: 0});
                });
               }

    ngOnInit() {
      this.spinner.show();
      this.jobList({ current_page: 0 });
    }

    handleChangeCity(city: string) {
      this.subject.sendCity(city);
    }

    jobList({current_page}) {
      // this.spinner.show();
      this.backend.getJobsList(current_page, 6, true, this.jobCity)
          .subscribe((resp: any) => {
            const { jobs, pages, current_page, ok } = resp;
            if (ok) {
               this.currentPage = current_page;
               this.dataJobsList = jobs;
               this.totalPages = pages;
            }
            this.spinner.hide();
          }, (err) => {
               this.spinner.hide();
               this.dataJobsList = [];
          });
    }
}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-legal',
  templateUrl: './legal.component.html',
  styleUrls: ['./legal.component.scss']
})
export class LegalComponent implements OnInit {

  public pdfSource1 = 'assets/pdf/terminos-y-condiciones.pdf';
  public pdfSource2 = 'assets/pdf/tratamiento-de-datos.pdf';

  constructor() { }

  ngOnInit() {
  }

}

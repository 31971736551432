import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SubjectService {

  private selectedError = new Subject<any>();
  eventSelectedError$ = this.selectedError.asObservable();

  private selectedCity = new Subject<any>();
  eventSelectedCity$ = this.selectedCity.asObservable();

  private stateMenuToggle = new Subject<any>();
  eventStateMenu$ = this.stateMenuToggle.asObservable();

  private selectedState = new Subject<any>();
  eventState$ = this.selectedState.asObservable();

  private selectedDate = new Subject<any>();
  eventDate$ = this.selectedDate.asObservable();

  private showStateFilter = new Subject<any>();
  eventShowStateFilter$ = this.showStateFilter.asObservable();

  constructor() { }

  sendText(selected: any) {
    this.selectedError.next(selected);
  }

  sendCity(selected: any) {
    this.selectedCity.next(selected);
  }

  sendMenuToggle(state: boolean) {
    this.stateMenuToggle.next(state);
  }

  sendState(state: string) {
    this.selectedState.next(state);
  }

  sendDate(date: Date) {
    this.selectedDate.next(date);
    console.log('date: ', date);
    
  }

  toggleStateFilter(state: boolean) {
    this.showStateFilter.next(state);
  }
}

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { IdentityModel } from 'src/app/models/identity.model';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  // properties
   private apiUrl: string;
   public headers: HttpHeaders;
   public identity: IdentityModel = new IdentityModel();
   public userToken: any;

  constructor( private http: HttpClient ) {
    this.apiUrl = environment.apiUrl;
    this.readToken();
  }

  // Login
  login( usuario: any  ) {
    this.isAutenticated();
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    usuario.contrasena = usuario.contrasena;
    const options = {
      params: null, headers
    };

    return this.http.post(
      `${this.apiUrl}/login`, usuario, options
    ).pipe(
      map( (resp: any) => {
         // Expires token
         const expiraToken = new Date(resp.expira);
         this.saveToken( resp['TOKEN'] );
         return resp;
      })
    );

  }

  private saveToken( token: string ) {
    this.userToken = token;
    localStorage.setItem('tokenWWU', token);
  }

  readToken() {
    if ( localStorage.getItem('tokenWWU') ) {
      this.userToken = localStorage.getItem('tokenWWU');
    } else {
      this.userToken = '';
    }
    return this.userToken;
  }

  logout() {
    localStorage.removeItem('tokenWWU');
  }

  getIdentity() {
   return this.identity = JSON.parse(localStorage.getItem('identityWWU'));
  }

  isAutenticated(): boolean {

  if (localStorage.getItem('tokenWWU') === undefined || localStorage.getItem('tokenWWU') === null) {
      return false;
    } else {
      return this.userToken;
    }
  }


}

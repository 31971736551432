import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { PasswordComponent } from './password/password.component';
import { AuthComponent } from './auth.component';

const routes: Routes = [
 { path: 'admin',
   component: AuthComponent,
   children: [
    { path: 'login', component: LoginComponent },
    { path: 'contrasena', component: PasswordComponent },
    { path: '**', pathMatch: 'full', redirectTo:  '/app/empleos' },
   ]
  },
];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forChild(routes)
  ],
  exports: [
    RouterModule
  ]
})
export class AuthRoutingModule { }

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BackEndService } from 'src/app/services/back-end.service';
import { ModalService } from 'src/app/services/modal.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { CandidateModel } from 'src/app/models/candidate.model';
import Swal from 'sweetalert2';
declare let gtag: any;

@Component({
  selector: 'app-job-detail',
  templateUrl: './job-detail.component.html',
  styleUrls: ['./job-detail.component.scss']
})
export class JobDetailComponent implements OnInit {

  public id: string;
  public dataJob: any;
  public jobName: string;
  public jobCity: string;

  constructor(private activedRoute: ActivatedRoute,
              private backend: BackEndService,
              private spinner: NgxSpinnerService,
              private router: Router,
              private modal: ModalService) {
    this.activedRoute.params.subscribe((params: any) => {
      this.id = params.id;
    });
  }

  ngOnInit() {
    this.getJob();
  }

  getJob() {
    this.spinner.show();
    this.backend.getJob(this.id)
        .subscribe((resp: any) => {
         this.dataJob = resp.job[0];
         this.spinner.hide();
        }, (err) => {
          console.log(err);
          this.spinner.hide();
        });
  }


  modalApply(name: string, city: string) {
    this.modal.show('fileUpload');
    this.jobName = name;
    this.jobCity = city;
    (window as any).gtag('event', 'modal_aplicar',
                         { event_category: `${name}-${city}` , event_label: ''});
  }

  postNewCandidate(candidate: CandidateModel) {
    this.spinner.show();
    this.backend.postNewCandidate(candidate)
        .subscribe((resp: any) => {
          this.spinner.hide();
          Swal.fire({
            type: 'success',
            title: `Postulación exitosa`,
            text: `Pronto estaremos en contacto...`,
          }).then((result) => {
            this.router.navigateByUrl('/app/empleos');
          });
          (window as any).gtag('event', 'aplicar',
                              { event_category: `${this.jobName}-${this.jobCity}` , event_label: ''});
        }, (err: any) => {
          console.log(err);
          this.spinner.hide();
          Swal.fire({
            type: 'error',
            title: `No está disponible.`,
            text: `Por favor intente más tarde.`,
          });
          (window as any).gtag('event', 'fallo_aplicar',
                              { event_category: `${this.jobName}-${this.jobCity}` , event_label: ''});
        });
  }

}
